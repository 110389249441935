import { queryClient } from '@/graphql/client';
import { PolicyActionTypeInput, PolicyObjectTypeInput } from '@/graphql/codegen/graphql';
import { CREATE_PERMISSION_POLICY, DELETE_PERMISSION_POLICY } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useAccount } from '@/hooks/useAccount';
import { ENTITLEMENT_NAME } from '@/hooks/useEntitlements';
import { usePermissionPolicies } from '@/hooks/usePermissionPolicies';
import {
  setAllAdminChecked,
  toggleExpandedPermission,
  usePermissionStore,
} from '@/stores/permission';
import { cn } from '@/utils/classname';
import { parseAllRawValue } from '@/utils/misc';
import { CheckBox } from '../Common/CheckBox';
import { useMutation } from '@tanstack/react-query';
import { Row } from '@tanstack/react-table';
import { ChangeEvent, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  ACTION_TYPE_MAP,
  ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP,
  OBJECT_TYPE_TO_ACTION_TYPES_MAP,
} from '../../constants/permissions';
import { Resource } from './columns';
import { AccessGate } from '../AccessGate';

export const PermissionCell = ({ row }: { row: Row<Resource> }) => {
  const { actionTypes, subjectType } = row.original;
  const isAllAdminChecked = usePermissionStore(state => state.isAllAdminChecked);
  const expandedPermissions = usePermissionStore(state => state.expandedPermissions);
  const typesRef = useRef<any>(null);

  const { id } = useParams<{ id: string }>();
  const accountId = useAccount().account?.id;

  const currObjectTypeActionTypes =
    OBJECT_TYPE_TO_ACTION_TYPES_MAP[
      parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput
    ];

  const addResource = useMutation({
    mutationFn: (actionType: PolicyActionTypeInput) =>
      request(CREATE_PERMISSION_POLICY, {
        policies: [
          {
            accountId: accountId as string,
            actionType,
            subjectId: id,
            subjectType: subjectType,
            objectId: row.original.objectId,
            objectType: parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput,
          },
        ],
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(
        usePermissionPolicies.getQueryKey({
          actionType: null,
          subjectId: id,
          subjectType: subjectType,
          objectId: null,
          objectType: null,
        }),
      );
    },
  });

  const removeResource = useMutation({
    mutationFn: (actionType: PolicyActionTypeInput) =>
      request(DELETE_PERMISSION_POLICY, {
        policies: [
          {
            accountId: accountId as string,
            actionType,
            subjectId: id,
            subjectType: subjectType,
            objectId: row.original.objectId,
            objectType: parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput,
          },
        ],
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(
        usePermissionPolicies.getQueryKey({
          actionType: null,
          subjectId: id,
          subjectType: subjectType,
          objectId: null,
          objectType: null,
        }),
      );
    },
  });

  const removeAdminAllResource = useMutation({
    mutationFn: () =>
      request(DELETE_PERMISSION_POLICY, {
        policies: [
          {
            accountId: accountId as string,
            actionType: PolicyActionTypeInput.Admin,
            subjectId: id,
            subjectType: subjectType,
            objectId: '*',
            objectType: PolicyObjectTypeInput.All,
          },
        ],
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(
        usePermissionPolicies.getQueryKey({
          actionType: null,
          subjectId: id,
          subjectType: subjectType,
          objectId: null,
          objectType: null,
        }),
      );
    },
  });

  useEffect(() => {
    if (isAllAdminChecked) {
      addResource.mutate(PolicyActionTypeInput.Admin);
    }
  }, [isAllAdminChecked]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, actionType: PolicyActionTypeInput) => {
    const { checked } = e.target;
    const isSubAction = types.find(type => type.actionType === actionType)?.isSubAction || false;
    const activePermissions = new Set<PolicyActionTypeInput>();

    let inheritInProgress = false;
    for (const permissionType of currObjectTypeActionTypes) {
      if (actionTypes.includes(permissionType) || inheritInProgress) {
        inheritInProgress = true;
        activePermissions.add(permissionType);
      }

      const subActionTypesMap = ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP[
        permissionType as keyof typeof ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP
      ]?.[
        row.original.objectType as keyof (typeof ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP)[keyof typeof ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP]
      ];

      if (subActionTypesMap) {
        for (const subType of subActionTypesMap) {
          if (actionTypes.includes(subType)) {
            inheritInProgress = true;
            activePermissions.add(subType);
          }
        }
      }
    }

    if (!checked) {
      setAllAdminChecked(false);
      removeAdminAllResource.mutate();
    }

    const isLowestPermission = actionType === currObjectTypeActionTypes[currObjectTypeActionTypes.length - 1];

    if (isLowestPermission && !checked) {
      for (const permissionType of currObjectTypeActionTypes) {
        removeResource.mutate(permissionType);

        const subActionTypesMap = ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP[
          permissionType as keyof typeof ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP
        ]?.[
          row.original.objectType as keyof (typeof ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP)[keyof typeof ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP]
        ];

        if (subActionTypesMap) {
          for (const subType of subActionTypesMap) {
            removeResource.mutate(subType);
          }
        }
      }
      return;
    }

    if (isSubAction) {
      const parentActionType = Object.entries(ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP).find(
        ([_, subActionsMap]) => {
          const subActions = subActionsMap[row.original.objectType as keyof typeof subActionsMap] || [];
          return subActions.includes(actionType);
        },
      )?.[0] as PolicyActionTypeInput | undefined;

      const parentActionTypeExists = parentActionType !== undefined;
      if (parentActionTypeExists) {
        const siblingActionTypes = ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP[
          parentActionType as keyof typeof ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP
        ][
          row.original.objectType as keyof (typeof ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP)[keyof typeof ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP]
        ];

        if (checked) {
          const allSiblingsChecked = siblingActionTypes.every(
            (siblingType: PolicyActionTypeInput) =>
              siblingType === actionType || activePermissions.has(siblingType)
          );

          if (allSiblingsChecked) {
            addResource.mutate(parentActionType);
          } else {
            addResource.mutate(actionType);
          }
        } else {
          removeResource.mutate(parentActionType);

          const allSiblingsUnchecked = siblingActionTypes.every(
            (siblingType: PolicyActionTypeInput) => {
              const isNotActive = !activePermissions.has(siblingType) && !activePermissions.has(parentActionType);
              return isNotActive || siblingType === actionType;
            }
          );

          if (allSiblingsUnchecked) {
            const nextLowerPermission = currObjectTypeActionTypes[currObjectTypeActionTypes.indexOf(parentActionType) + 1];
            const canDowngrade = parentActionType !== nextLowerPermission;

            if (canDowngrade) {
              addResource.mutate(nextLowerPermission);
            } else {
              removeResource.mutate(actionType);
            }
          } else {
            // Remove the current sub-action type but add the other sibling action types
            removeResource.mutate(actionType);
            for (const siblingType of siblingActionTypes) {
              if (siblingType !== actionType) {
                addResource.mutate(siblingType);
              }
            }
          }
        }
      }
      return;
    }

    if (checked) {
      addResource.mutate(actionType);
      return;
    } else {
      removeResource.mutate(actionType);
    }

    const nextLowerPermission = currObjectTypeActionTypes[currObjectTypeActionTypes.indexOf(actionType) + 1];
    const canDowngrade = actionType !== nextLowerPermission;

    if (!canDowngrade) {
      removeResource.mutate(actionType);
      return;
    }

    addResource.mutate(nextLowerPermission);
  };

  const types = useMemo(() => {
    const permissionTypes: {
      name: string;
      checked: boolean;
      disabled: boolean;
      actionType: PolicyActionTypeInput;
      isParent?: boolean;
      isSubAction?: boolean;
      parentType?: PolicyActionTypeInput;
      partiallyChecked?: boolean;
    }[] = [];

    let isChecked = false;
    let hasActiveSubAction = false;

    for (const permissionType of currObjectTypeActionTypes) {
      const isIncluded = actionTypes.includes(permissionType);
      if (isIncluded || hasActiveSubAction) isChecked = true;

      const currentPermissionType = permissionType as PolicyActionTypeInput;
      const objectType = parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput;
      const subActionTypesMap = ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP[
          currentPermissionType as keyof typeof ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP
        ];
      const subActionTypes = subActionTypesMap?.[objectType as keyof typeof subActionTypesMap];
      const hasSubActions = subActionTypes !== undefined;

      const hasPartialSelection = hasSubActions &&
        subActionTypes.some((subType: PolicyActionTypeInput) =>
          actionTypes.includes(subType)
        );

      permissionTypes.push({
        checked: isChecked,
        disabled: false,
        actionType: permissionType,
        name: ACTION_TYPE_MAP[permissionType as PolicyActionTypeInput].name === 'List'
            ? 'Restricted'
            : ACTION_TYPE_MAP[permissionType as PolicyActionTypeInput].name,
        isParent: hasSubActions,
        partiallyChecked: hasPartialSelection,
      });

      for (const [parent, subActionTypesMap] of Object.entries(ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP)) {
        if (parent === permissionType) {
          const subActionTypes = subActionTypesMap[row.original.objectType as keyof typeof subActionTypesMap];
          if (subActionTypes) {
            subActionTypes.forEach((subType: PolicyActionTypeInput) => {
              const isSubTypeChecked = isChecked || actionTypes.includes(subType);
              
              if (isSubTypeChecked) {
                hasActiveSubAction = true;
              }

              permissionTypes.push({
                checked: isSubTypeChecked,
                disabled: false,
                actionType: subType,
                name: ACTION_TYPE_MAP[subType].name === 'List' ? 'Restricted' : ACTION_TYPE_MAP[subType].name,
                isSubAction: true,
                parentType: permissionType as PolicyActionTypeInput,
              });
            });
          }
        }
      }
    }

    return permissionTypes;
  }, [actionTypes, currObjectTypeActionTypes]);

  useEffect(() => {
    typesRef.current = [...types];
  }, [types]);

  const getExpandKey = (actionType: string) => {
    return `${row.id}-${actionType}`;
  };

  const toggleExpand = (actionType: string) => {
    toggleExpandedPermission(getExpandKey(actionType));
  };

  return types.map(
    ({ actionType, name, checked, isParent, isSubAction, parentType, partiallyChecked }) => {
      // Hide sub-actions if parent is not expanded
      if (isSubAction && parentType && !expandedPermissions.has(getExpandKey(parentType))) {
        return null;
      }

      return (
        <AccessGate
          disabled={() => (
            <div
              className={cn(
                'mt-3 flex items-center color-neutral-800 typo-text-s first:mt-0 opacity-50',
                isSubAction && 'ml-4',
              )}
            >
              <div className="w-5 flex justify-center mr-2">
                {isParent && (
                  <div
                    className={cn(
                      'i-skand-dropdownarrow cursor-pointer',
                      'text-neutral-400',
                      !expandedPermissions.has(getExpandKey(actionType)) && 'rotate-270',
                    )}
                    onClick={() => toggleExpand(actionType)}
                  />
                )}
              </div>
              <label className="flex items-center gap-3 flex-1">
                <CheckBox
                  checked={checked}
                  indeterminate={partiallyChecked}
                  disabled={true}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, actionType as PolicyActionTypeInput)}
                />
                {name}
              </label>
            </div>
          )}
          enabled={() => (
            <div
              className={cn(
                'mt-3 flex items-center color-neutral-800 typo-text-s first:mt-0',
                isSubAction && 'ml-4',
              )}
            >
              <div className="w-5 flex justify-center mr-2">
                {isParent && (
                  <div
                    className={cn(
                      'i-skand-dropdownarrow cursor-pointer',
                      'text-neutral-400',
                      !expandedPermissions.has(getExpandKey(actionType)) && 'rotate-270',
                    )}
                    onClick={() => toggleExpand(actionType)}
                  />
                )}
              </div>
              <label className="flex items-center gap-3 flex-1 hover:cursor-pointer">
                <CheckBox
                  checked={checked}
                  indeterminate={partiallyChecked}
                  className={cn({ 'hover:cursor-pointer': true })}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, actionType as PolicyActionTypeInput)}
                />
                {name}
              </label>
            </div>
          )}
          entitlementCheck={{ featureName: ENTITLEMENT_NAME.PERMISSION }}
          key={actionType}
          loading={() => null}
        />
      );
    },
  );
};
