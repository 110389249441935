import { Back } from '@/components/Back';
import { ConfirmationModal, ConfirmationModalState } from '@/components/ConfirmationModal';
import { SideBar } from '@/components/SideBar';
import { SideTabPanel } from '@/components/SideTabPanel';
import * as PATHS from '@/constants/paths';
import { useReadUserById } from '@/hooks/useReadUserById';
import { useUserDetailsPageActions } from '@/hooks/useUserDetailsPageActions';
import { cn } from '@/utils/classname';
import { User } from '@/utils/interfaces';
import { getUsersPagesSidePanelLinks } from '@/utils/links';
import { Button, Input } from '@skand/ui';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DeactivateButton } from './DeactivateButton';
import { ReactivateButton } from './ReactivateButton';
import { ResendInviteButton } from './ResendInviteButton';

type UserStatus = User['status'];

enum UserStatusTypes {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  INVITED = 'INVITED',
}

export const UsersDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const user = useReadUserById(id);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [confirmationModalState, setConfirmationModalState] = useState<ConfirmationModalState>({
    isOpen: false,
    title: '',
    description: '',
    actionButton: '',
    actionFunction: () => null,
  });

  const { handleSubmitEditUserDetails, handleSubmitResetPassword, handleConfirmationModal } =
    useUserDetailsPageActions(
      user?.id ?? '',
      firstName,
      lastName,
      email,
      setConfirmationModalState,
    );

  const userStatus = user?.status as UserStatus;

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
    }
  }, [user]);

  const isUserAbleToUpdateOrResetPassword = (status: UserStatus) =>
    ['INVITED', 'DEACTIVATED'].includes(status);

  const userDetailsInputFieldsConfig = [
    {
      label: 'First name',
      state: { inputValue: firstName, inputChangeHandler: setFirstName },
      isDisabled: isUserAbleToUpdateOrResetPassword,
      inputFieldHint: "Unable to change user's first name",
    },
    {
      label: 'Last name',
      state: { inputValue: lastName, inputChangeHandler: setLastName },
      isDisabled: isUserAbleToUpdateOrResetPassword,
      inputFieldHint: "Unable to change user's last name",
    },
    {
      label: 'Email',
      state: { inputValue: email, inputChangeHandler: setEmail },
      isDisabled: () => true,
      inputFieldHint: '',
    },
  ];

  const userNameDisplay = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;

  return (
    <div
      className={cn('h-full', 'w-full', 'flex', 'flex-nowrap', 'bg-neutral-100', 'overflow-hidden')}
    >
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          confirmationModalState={confirmationModalState}
          setConfirmationModalState={setConfirmationModalState}
        />
      )}
      <SideBar />
      <div className={cn('h-full', 'w-full', 'px-6', 'overflow-auto')}>
        <div
          className={cn(
            'b-b-1 b-b-solid b-b-neutral-300',
            'flex items-center justify-between',
            'p-b-3 p-t-30px',
          )}
        >
          <h1
            className="ml-4 max-w-800px truncate color-neutral-800 typo-heading-3"
            title={user && userNameDisplay}
          >
            {user && userNameDisplay}
          </h1>
          <div className="ml-auto flex gap-2">
            <Back to={PATHS.MANAGE_USERS}>Back to all users</Back>
          </div>
        </div>
        <div className="ml-4 mt-3 h-[64px] flex items-start justify-between">
          <p className="color-neutral-800 typo-text-s">Manage user details.</p>
        </div>
        <div className="flex">
          <div className="ml-4 flex flex-col gap-6">
            <form className="w-360px flex flex-col gap-6">
              <p className="color-neutral-800 typo-heading-4">Edit user details</p>
              <div className="flex flex-col gap-3">
                {userDetailsInputFieldsConfig.map(
                  (
                    {
                      label,
                      state: { inputValue, inputChangeHandler },
                      isDisabled,
                      inputFieldHint,
                    },
                    index,
                  ) => (
                    <div
                      className={cn(userStatus && isDisabled(userStatus) && 'opacity-50')}
                      key={index}
                    >
                      <Input
                        disabled={userStatus && isDisabled(userStatus)}
                        hint={userStatus && isDisabled(userStatus) ? inputFieldHint : ''}
                        label={label}
                        onChange={inputChangeHandler as (inputValue: string) => void}
                        value={inputValue}
                      />
                    </div>
                  ),
                )}
              </div>
              <Button
                className="cursor-pointer"
                disabled={userStatus && isUserAbleToUpdateOrResetPassword(userStatus)}
                onClick={e => {
                  e.preventDefault();
                  handleConfirmationModal({
                    isOpen: true,
                    title: 'Update user details',
                    description: "Are you sure you want to update user's details",
                    actionButton: "Update user's details",
                    actionFunction: handleSubmitEditUserDetails,
                  });
                }}
                primary
                size="s"
              >
                Update
              </Button>
            </form>
            <form className="w-360px flex flex-col gap-6">
              <p className="color-neutral-800 typo-heading-4">Manage password</p>
              <Button
                className="cursor-pointer"
                disabled={userStatus && isUserAbleToUpdateOrResetPassword(userStatus)}
                onClick={e => {
                  e.preventDefault();
                  handleConfirmationModal({
                    isOpen: true,
                    title: 'Reset password',
                    description: "Are you sure you want to reset this user's password?",
                    actionButton: 'Reset',
                    actionFunction: handleSubmitResetPassword,
                  });
                }}
                primary
                size="s"
              >
                Reset Password
              </Button>
            </form>
            <form className="w-360px flex flex-col gap-6" onSubmit={e => e.preventDefault()}>
              <p className="color-neutral-800 typo-heading-4">User controls</p>
              {userStatus === UserStatusTypes.ACTIVE && (
                <DeactivateButton
                  handleConfirmationModal={handleConfirmationModal}
                  user={user as User}
                />
              )}
              {userStatus === UserStatusTypes.DEACTIVATED && (
                <ReactivateButton
                  handleConfirmationModal={handleConfirmationModal}
                  user={user as User}
                />
              )}
              {userStatus === UserStatusTypes.INVITED && (
                <ResendInviteButton
                  handleConfirmationModal={handleConfirmationModal}
                  user={user as User}
                />
              )}
            </form>
          </div>
          <SideTabPanel links={getUsersPagesSidePanelLinks(id)} />
        </div>
      </div>
    </div>
  );
};
