import { AddResources } from '@/components/AddResources';
import { Back } from '@/components/Back';
import { SideBar } from '@/components/SideBar';
import { SideTabPanel } from '@/components/SideTabPanel';
import { Table } from '@/components/Table';
import * as PATHS from '@/constants/paths';
import { OBJECT_ID } from '@/constants/policy';
import {
  PolicyObjectTypeInput,
  PolicySubjectTypeInput,
  Role,
  User,
  UserGroup,
} from '@/graphql/codegen/graphql';
import { usePermissionPolicies } from '@/hooks/usePermissionPolicies';
import { setDisablePermissionActions } from '@/stores/subjectPermissionsPage';
import { cn } from '@/utils/classname';
import { getSubjectsPagesSidePanelLinks } from '@/utils/links';
import { parseAllRawValue } from '@/utils/misc';
import { displayName } from '@/utils/user';
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { RESOURCE_KEY } from '../../constants/permissions';
import { CircularLoadingIndicator } from '../CircularLoadingIndicator';
import { Resource, columns } from './columns';

interface SubjectPermissionsPageProps {
  id: string;
  subject: SolidId<User | UserGroup | Role> | null;
  subjectType: PolicySubjectTypeInput;
  disablePermissionActions?: boolean;
}

export const SubjectPermissionsPage = ({
  id,
  subject,
  subjectType,
  disablePermissionActions,
}: SubjectPermissionsPageProps) => {
  useEffect(() => {
    setDisablePermissionActions(disablePermissionActions ?? false);
  }, [disablePermissionActions]);

  const { policies } = usePermissionPolicies(
    {
      actionType: null,
      subjectId: id,
      subjectType: subjectType as PolicySubjectTypeInput,
      objectId: null,
      objectType: null,
    },
    { enabled: !!id },
  );

  const resources = useMemo(() => {
    const map = new Map<string, Resource>();

    const { sceneEntityIds, systemNodeIds } = policies.reduce(
      (acc, currPolicy) => {
        if (currPolicy.objectId === OBJECT_ID.ALL) {
          return acc;
        }

        if (currPolicy.objectType === PolicyObjectTypeInput.SystemNode) {
          return { ...acc, systemNodeIds: [...acc.systemNodeIds, currPolicy.objectId as string] };
        }
        if (currPolicy.objectType === PolicyObjectTypeInput.SceneEntity) {
          return { ...acc, sceneEntityIds: [...acc.sceneEntityIds, currPolicy.objectId as string] };
        }

        return acc;
      },
      { sceneEntityIds: [], systemNodeIds: [] } as {
        sceneEntityIds: string[];
        systemNodeIds: string[];
      },
    );

    for (const policy of policies) {
      const key = `${parseAllRawValue(policy.objectType as string)}-${policy.objectId}`;
      const resource = map.get(key);
      if (resource) {
        resource.policies.push(policy);
        resource.actionTypes.push(policy.actionType ?? '');
      } else {
        map.set(key, {
          key,
          subjectType,
          actionTypes: [policy.actionType ?? ''],
          objectId: policy.objectId ?? '',
          objectType: policy.objectType ?? '',
          policies: [policy],
          ...(policy.objectType === PolicyObjectTypeInput.SystemNode && { systemNodeIds }),
          ...(policy.objectType === PolicyObjectTypeInput.SceneEntity && { sceneEntityIds }),
        });
      }
    }

    const predefined_resources: Resource[] = [];
    if (!map.has(RESOURCE_KEY.ALL)) {
      predefined_resources.push({
        actionTypes: [],
        key: RESOURCE_KEY.ALL,
        subjectType,
        objectId: OBJECT_ID.ALL,
        objectType: PolicyObjectTypeInput.All,
        policies: [],
      });
    }

    if (!map.has(RESOURCE_KEY.PROJECT)) {
      predefined_resources.push({
        actionTypes: [],
        key: RESOURCE_KEY.PROJECT,
        subjectType,
        objectId: OBJECT_ID.ALL,
        objectType: PolicyObjectTypeInput.Project,
        policies: [],
      });
    }

    if (!map.has(RESOURCE_KEY.PROJECT_GROUP)) {
      predefined_resources.push({
        actionTypes: [],
        key: RESOURCE_KEY.PROJECT_GROUP,
        subjectType,
        objectId: OBJECT_ID.ALL,
        objectType: PolicyObjectTypeInput.ProjectGroup,
        policies: [],
      });
    }

    if (!map.has(RESOURCE_KEY.SYSTEM_NODE)) {
      predefined_resources.push({
        actionTypes: [],
        key: RESOURCE_KEY.SYSTEM_NODE,
        subjectType,
        objectId: OBJECT_ID.ALL,
        objectType: PolicyObjectTypeInput.SystemNode,
        policies: [],
      });
    }

    const resources = [...predefined_resources, ...map.values()]
      .sort((a, b) => a.key.localeCompare(b.key))
      .sort((a, b) => {
        const isWildcardA = a.key.includes('-*');
        const isWildcardB = b.key.includes('-*');

        if (isWildcardA && isWildcardB) {
          return a.key.localeCompare(b.key);
        }

        if (isWildcardA) {
          return -1;
        }

        if (isWildcardB) {
          return 1;
        }

        return a.key.localeCompare(b.key);
      });
    return resources;
  }, [policies, subjectType]);

  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data: resources,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.key,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: { sorting },
  });

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      <SideBar />
      <div className={cn('h-full', 'w-full', 'px-6', 'overflow-auto')}>
        <div
          className={cn(
            'b-b-1',
            'b-b-neutral-300',
            'b-b-solid',
            'flex',
            'items-center',
            'p-b-3',
            'p-t-30px',
          )}
        >
          <h1 className="ml-4 color-neutral-800 typo-heading-3">
            {!subject ? (
              <CircularLoadingIndicator />
            ) : (
              <>
                {subjectType === PolicySubjectTypeInput.User && displayName(subject as User)}
                {subjectType === PolicySubjectTypeInput.Group && (subject as UserGroup).name}
                {subjectType === PolicySubjectTypeInput.Role && (subject as Role).name}
              </>
            )}
          </h1>

          <div className="ml-auto flex gap-2">
            <>
              {subjectType === PolicySubjectTypeInput.User && (
                <Back to={PATHS.MANAGE_USERS}>Back to all users</Back>
              )}
              {subjectType === PolicySubjectTypeInput.Group && (
                <Back to={PATHS.MANAGE_GROUPS}>Back to all groups</Back>
              )}
              {subjectType === PolicySubjectTypeInput.Role && (
                <Back to={PATHS.MANAGE_ROLES}>Back to all roles</Back>
              )}
            </>

            {/* We are not allowed users to add permissions of individual resources for roles for now */}
            {subjectType !== PolicySubjectTypeInput.Role && (
              <AddResources subjectType={subjectType} />
            )}
          </div>
        </div>

        <div className="ml-4 mt-3 h-[64px] flex items-start justify-between">
          <p className="color-neutral-800 typo-text-s">
            {subjectType === PolicySubjectTypeInput.User && 'Manage permissions for this user.'}
            {subjectType === PolicySubjectTypeInput.Group &&
              'Manage permissions for this user group.'}
            {subjectType === PolicySubjectTypeInput.Role && 'Manage permissions for the role.'}
          </p>
        </div>

        <div className="w-full flex">
          <Table table={table} tdProps={{ className: 'vertical-start' }} />

          <SideTabPanel
            links={getSubjectsPagesSidePanelLinks({
              id,
              subjectType,
            })}
          />
        </div>
      </div>
    </div>
  );
};
