import { cn } from '@/utils/classname';
import { InputHTMLAttributes, forwardRef, useEffect, useRef } from 'react';
import './Checkbox.css';

/**
 * This CheckBox component is a drop-in replacement for @skand/ui's CheckBox
 * with added support for indeterminate state.
 * 
 * INTEGRATION NOTE: 
 * For a more permanent solution, consider:
 * 
 * 1. Adding the 'boxpartial' SVG to the @skand/icon package:
 *    - See src/components/Common/icons.ts for the SVG definition
 * 
 * 2. Updating the UnoCSS configuration to include the new icon:
 *    - In the Skand UI package, locate where icons are defined for UnoCSS
 *    - Add the new icon: `["i-skand-boxpartial", boxpartial]`
 * 
 * 3. Adding indeterminate support to the original CheckBox component in @skand/ui
 */

export interface CheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean;
}

/**
 * An enhanced CheckBox component that supports three states:
 * - Checked: Shows checkmark icon (i-skand-boxchecked)
 * - Unchecked: Shows empty box icon (i-skand-boxunchecked)
 * - Indeterminate: Shows minus/dash icon (i-skand-boxpartial)
 */
export const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>(
  ({ className, indeterminate = false, ...props }, ref) => {
    const internalRef = useRef<HTMLInputElement>(null);
    
    // Use the forwardRef if provided, otherwise use our internal ref
    const inputRef = (ref || internalRef) as React.RefObject<HTMLInputElement>;
    
    // Set the indeterminate property on the input element
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.indeterminate = indeterminate;
      }
    }, [indeterminate, inputRef]);
    
    return (
      <input
        className={cn(
          'appearance-none',
          'checked:color-neutral-600',
          'checked:i-skand-boxchecked',
          'color-neutral-600',
          // Use a custom class for indeterminate state
          indeterminate ? 'i-skand-boxpartial' : 'i-skand-boxunchecked',
          'text-3',
          className,
        )}
        ref={inputRef}
        type="checkbox"
        {...props}
      />
    );
  },
);

CheckBox.displayName = 'CheckBox';
