import { create } from 'zustand';

interface PermissionPolicyStore {
  isAllAdminChecked: boolean;
  expandedPermissions: Set<string>;
}

export const usePermissionStore = create<PermissionPolicyStore>()(() => ({
  isAllAdminChecked: false,
  expandedPermissions: new Set<string>(),
}));

export const useSubjectPermissionPage = create()(() => ({
  subjectType: '',
}));

export const setAllAdminChecked = (isAllAdminChecked: boolean) => {
  usePermissionStore.setState({ isAllAdminChecked });
};

export const setExpandedPermissions = (expandedPermissions: Set<string>) => {
  usePermissionStore.setState({ expandedPermissions });
};

export const toggleExpandedPermission = (actionType: string) => {
  usePermissionStore.setState(state => {
    const next = new Set(state.expandedPermissions);
    if (next.has(actionType)) {
      next.delete(actionType);
    } else {
      next.add(actionType);
    }
    return { expandedPermissions: next };
  });
};

export const setSubjectPermissionPage = (subjectType: string) => {
  useSubjectPermissionPage.setState({ subjectType });
};
