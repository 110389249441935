import { OBJECT_ID } from '@/constants/policy';
import { PolicyActionTypeInput, PolicyObjectTypeInput } from '@/graphql/codegen/graphql';
import { ReactNode } from 'react';

export interface ActionTypeItem {
  name: string;
  description: ReactNode;
}

export const OBJECT_TYPE_TO_ACTION_TYPES_MAP = {
  [PolicyObjectTypeInput.All]: [PolicyActionTypeInput.Admin],
  [PolicyObjectTypeInput.ProjectGroup]: [
    PolicyActionTypeInput.Admin,
    PolicyActionTypeInput.Edit,
    PolicyActionTypeInput.Read,
    PolicyActionTypeInput.List,
  ],
  [PolicyObjectTypeInput.Project]: [
    PolicyActionTypeInput.Admin,
    PolicyActionTypeInput.Edit,
    PolicyActionTypeInput.Read,
    PolicyActionTypeInput.List,
  ],
  [PolicyObjectTypeInput.ProjectNode]: [
    PolicyActionTypeInput.Admin,
    PolicyActionTypeInput.Edit,
    PolicyActionTypeInput.Read,
    PolicyActionTypeInput.List,
  ],
  [PolicyObjectTypeInput.SceneEntity]: [
    PolicyActionTypeInput.Admin,
    PolicyActionTypeInput.Edit,
    PolicyActionTypeInput.Read,
  ],
  [PolicyObjectTypeInput.SystemNode]: [
    PolicyActionTypeInput.Admin,
    PolicyActionTypeInput.Delete,
    PolicyActionTypeInput.Move,
    PolicyActionTypeInput.Edit,
    PolicyActionTypeInput.Read,
  ],
};

export const ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP = {
  [PolicyActionTypeInput.Read]: {
    [PolicyObjectTypeInput.Project]: [
      PolicyActionTypeInput.ReadSystemNodes,
      PolicyActionTypeInput.ReadSceneEntities,
    ],
  },
};

const COMMON_ACTION_DESCRIPTION = {
  [PolicyActionTypeInput.Admin]: (
    <>
      All functions <span className="typo-text-s-em">including</span> managing user access.
    </>
  ),
  [PolicyActionTypeInput.List]: (
    <>Can see the presence of other assets that user do not have access to download.</>
  ),
};

export const ACTION_TYPE_MAP: Record<
  PolicyActionTypeInput,
  { name: string; descriptions: Record<PolicyObjectTypeInput, JSX.Element | null> }
> = {
  [PolicyActionTypeInput.Admin]: {
    name: 'Admin',
    descriptions: {
      [PolicyObjectTypeInput.All]: COMMON_ACTION_DESCRIPTION[PolicyActionTypeInput.Admin],
      [PolicyObjectTypeInput.Project]: COMMON_ACTION_DESCRIPTION[PolicyActionTypeInput.Admin],
      [PolicyObjectTypeInput.ProjectGroup]: COMMON_ACTION_DESCRIPTION[PolicyActionTypeInput.Admin],
      [PolicyObjectTypeInput.ProjectNode]: null,
      [PolicyObjectTypeInput.SceneEntity]: COMMON_ACTION_DESCRIPTION[PolicyActionTypeInput.Admin],
      [PolicyObjectTypeInput.SystemNode]: COMMON_ACTION_DESCRIPTION[PolicyActionTypeInput.Admin],
    },
  },
  [PolicyActionTypeInput.Delete]: {
    name: 'Delete',
    descriptions: {
      [PolicyObjectTypeInput.All]: null,
      [PolicyObjectTypeInput.Project]: null,
      [PolicyObjectTypeInput.ProjectGroup]: null,
      [PolicyObjectTypeInput.ProjectNode]: null,
      [PolicyObjectTypeInput.SceneEntity]: null,
      [PolicyObjectTypeInput.SystemNode]: (
        <>
          Full editing rights <span className="typo-text-s-em">including</span> deleting and moving
          files.
        </>
      ),
    },
  },
  [PolicyActionTypeInput.Move]: {
    name: 'Move',
    descriptions: {
      [PolicyObjectTypeInput.All]: null,
      [PolicyObjectTypeInput.Project]: null,
      [PolicyObjectTypeInput.ProjectGroup]: null,
      [PolicyObjectTypeInput.ProjectNode]: null,
      [PolicyObjectTypeInput.SceneEntity]: null,
      [PolicyObjectTypeInput.SystemNode]: (
        <>
          Editing rights <span className="typo-text-s-em">excluding</span> the ability to delete.
        </>
      ),
    },
  },
  [PolicyActionTypeInput.Edit]: {
    name: 'Edit',
    descriptions: {
      [PolicyObjectTypeInput.All]: null,
      [PolicyObjectTypeInput.Project]: (
        <>
          Editing rights <span className="typo-text-s-em">excluding</span> the ability to move and
          delete projects.
        </>
      ),
      [PolicyObjectTypeInput.ProjectGroup]: (
        <>
          Editing rights <span className="typo-text-s-em">excluding</span> the ability to move and
          delete project groups.
        </>
      ),
      [PolicyObjectTypeInput.ProjectNode]: null,
      [PolicyObjectTypeInput.SceneEntity]: (
        <>
          Editing rights <span className="typo-text-s-em">excluding</span> the ability to move and
          delete layers and layer groups.
        </>
      ),
      [PolicyObjectTypeInput.SystemNode]: (
        <>
          Editing rights (create and upload files) <span className="typo-text-s-em">excluding</span>{' '}
          the ability to move and delete.
        </>
      ),
    },
  },
  [PolicyActionTypeInput.EditRequester]: {
    name: 'Edit requester',
    descriptions: {
      [PolicyObjectTypeInput.All]: null,
      [PolicyObjectTypeInput.Project]: null,
      [PolicyObjectTypeInput.ProjectGroup]: null,
      [PolicyObjectTypeInput.ProjectNode]: null,
      [PolicyObjectTypeInput.SceneEntity]: null,
      [PolicyObjectTypeInput.SystemNode]: null,
    },
  },
  [PolicyActionTypeInput.Read]: {
    name: 'Read',
    descriptions: {
      [PolicyObjectTypeInput.All]: null,
      [PolicyObjectTypeInput.Project]: (
        <>Can view and download projects but cannot create or delete.</>
      ),
      [PolicyObjectTypeInput.ProjectGroup]: (
        <>Can view and download projects in project groups but cannot create or delete.</>
      ),
      [PolicyObjectTypeInput.ProjectNode]: null,
      [PolicyObjectTypeInput.SceneEntity]: (
        <>Can view and download layers and layer groups but cannot create or delete.</>
      ),
      [PolicyObjectTypeInput.SystemNode]: <>Read and download only &ndash; no editing.</>,
    },
  },
  [PolicyActionTypeInput.ReadSceneEntities]: {
    name: 'Layers',
    descriptions: {
      [PolicyObjectTypeInput.All]: null,
      [PolicyObjectTypeInput.Project]: (
        <>Can view and download layers and layer groups but cannot create or delete.</>
      ),
      [PolicyObjectTypeInput.ProjectGroup]: null,
      [PolicyObjectTypeInput.ProjectNode]: null,
      [PolicyObjectTypeInput.SceneEntity]: null,
      [PolicyObjectTypeInput.SystemNode]: null,
    },
  },
  [PolicyActionTypeInput.ReadSystemNodes]: {
    name: 'Files',
    descriptions: {
      [PolicyObjectTypeInput.All]: null,
      [PolicyObjectTypeInput.Project]: (
        <>Can view and download project files but cannot create or delete.</>
      ),
      [PolicyObjectTypeInput.ProjectGroup]: null,
      [PolicyObjectTypeInput.ProjectNode]: null,
      [PolicyObjectTypeInput.SceneEntity]: null,
      [PolicyObjectTypeInput.SystemNode]: null,
    },
  },
  [PolicyActionTypeInput.List]: {
    name: 'List',
    descriptions: {
      [PolicyObjectTypeInput.All]: null,
      [PolicyObjectTypeInput.Project]: <>Limited access to specified projects.</>,
      [PolicyObjectTypeInput.ProjectGroup]: (
        <>Limited access to specified projects and project groups.</>
      ),
      [PolicyObjectTypeInput.ProjectNode]: null,
      [PolicyObjectTypeInput.SceneEntity]: null,
      [PolicyObjectTypeInput.SystemNode]: null,
    },
  },
};

export const OBJECT_TYPE_MAP = {
  [PolicyObjectTypeInput.All]: 'All',
  [PolicyObjectTypeInput.Project]: 'Project',
  [PolicyObjectTypeInput.ProjectGroup]: 'Project group',
  [PolicyObjectTypeInput.ProjectNode]: 'Project node',
  [PolicyObjectTypeInput.SystemNode]: 'Folder',
  [PolicyObjectTypeInput.SceneEntity]: 'Layer',
};

export const PREDEFINED_RESOURCE_MAP = {
  [PolicyObjectTypeInput.All]: 'All',
  [PolicyObjectTypeInput.Project]: 'All projects',
  [PolicyObjectTypeInput.ProjectGroup]: 'All project groups',
  [PolicyObjectTypeInput.ProjectNode]: 'All project nodes',
  [PolicyObjectTypeInput.SystemNode]: 'All folders',
  [PolicyObjectTypeInput.SceneEntity]: 'All layers',
};

export const RESOURCE_KEY = {
  [PolicyObjectTypeInput.All]: `${PolicyObjectTypeInput.All}-${OBJECT_ID.ALL}`,
  [PolicyObjectTypeInput.Project]: `${PolicyObjectTypeInput.Project}-${OBJECT_ID.ALL}`,
  [PolicyObjectTypeInput.ProjectGroup]: `${PolicyObjectTypeInput.ProjectGroup}-${OBJECT_ID.ALL}`,
  [PolicyObjectTypeInput.SystemNode]: `${PolicyObjectTypeInput.SystemNode}-${OBJECT_ID.ALL}`,
  [PolicyObjectTypeInput.SceneEntity]: `${PolicyObjectTypeInput.SceneEntity}-${OBJECT_ID.ALL}`,
};
