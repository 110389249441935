import { queryClient } from '@/graphql/client';
import { PolicyActionTypeInput, PolicyObjectTypeInput } from '@/graphql/codegen/graphql';
import { CREATE_PERMISSION_POLICY, DELETE_PERMISSION_POLICY } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useAccount } from '@/hooks/useAccount';
import { ENTITLEMENT_NAME } from '@/hooks/useEntitlements';
import { usePermissionPolicies } from '@/hooks/usePermissionPolicies';
import { setAllAdminChecked } from '@/stores/permission';
import { cn } from '@/utils/classname';
import { parseAllRawValue } from '@/utils/misc';
import { CheckBox } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { Row } from '@tanstack/react-table';
import { ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { Resource } from './columns';
import { AccessGate } from '../AccessGate';

export const AdminAllPermissionCell = ({ row }: { row: Row<Resource> }) => {
  const { subjectType } = row.original;

  const { id } = useParams<{ id: string }>();
  const accountId = useAccount().account?.id;

  const addResource = useMutation({
    mutationFn: (actionType: PolicyActionTypeInput) =>
      request(CREATE_PERMISSION_POLICY, {
        policies: [
          {
            accountId: accountId as string,
            actionType,
            subjectId: id,
            subjectType: subjectType,
            objectId: row.original.objectId,
            objectType: parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput,
          },
        ],
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(
        usePermissionPolicies.getQueryKey({
          actionType: null,
          subjectId: id,
          subjectType: subjectType,
          objectId: null,
          objectType: null,
        }),
      );
    },
  });

  const removeResource = useMutation({
    mutationFn: (actionType: PolicyActionTypeInput) =>
      request(DELETE_PERMISSION_POLICY, {
        policies: [
          {
            accountId: accountId as string,
            actionType,
            subjectId: id,
            subjectType: subjectType,
            objectId: row.original.objectId,
            objectType: parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput,
          },
        ],
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(
        usePermissionPolicies.getQueryKey({
          actionType: null,
          subjectId: id,
          subjectType: subjectType,
          objectId: null,
          objectType: null,
        }),
      );
    },
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    if (checked) {
      setAllAdminChecked(true);
      addResource.mutate('ADMIN' as PolicyActionTypeInput);
    } else {
      setAllAdminChecked(false);
      removeResource.mutate('ADMIN' as PolicyActionTypeInput);
    }
  };

  const enabled = () => (
    <div className="flex items-center color-neutral-800 typo-text-s first:mt-0">
      <div className="w-5 flex justify-center mr-2"></div>
      <label className={cn('flex items-center gap-3 flex-1')} key={'ADMIN'}>
        <CheckBox
          checked={row.original.actionTypes.includes('ADMIN')}
          className={cn({ 'hover:cursor-pointer': true })}
          onChange={e => handleChange(e)}
        />
        Admin
      </label>
    </div>
  );

  const disabled = () => (
    <div className="flex items-center color-neutral-800 typo-text-s first:mt-0 opacity-50">
      <div className="w-5 flex justify-center mr-2"></div>
      <label className={cn('flex items-center gap-3 flex-1')} key={'ADMIN'}>
        <CheckBox
          checked={row.original.actionTypes.includes('ADMIN')}
          disabled
          onChange={e => handleChange(e)}
        />
        Admin
      </label>
    </div>
  );

  return (
    <AccessGate
      disabled={disabled}
      enabled={enabled}
      entitlementCheck={{ featureName: ENTITLEMENT_NAME.PERMISSION }}
      key={'ADMIN'}
      loading={disabled}
    />
  );
};
