import * as PATHS from '@/constants/paths';
import { useIsUserAdmin } from '@/hooks/useIsUserAdmin';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export const SetRegionRoute = ({ children, ...props }: RouteProps) => {
  const isAdmin = useIsUserAdmin();

  return (
    <Route {...props}>
      {isAdmin ? children : <Redirect to={PATHS.MANAGE_NO_REGION} />}
    </Route>
  );
};