import brand from '@/assets/brand.svg';
import { Checkbox } from '@/components/Checkbox';
import { useListAvailableRegionsForAccountContext } from '@/hooks/useListAvailableRegionsForAccountContext';
import { useUpdateRegionForAccountContext } from '@/hooks/useUpdateRegionForAccountContext';
import { cn } from '@/utils/classname';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dropdown, DropdownItem, Spinner } from '@skand/ui';
import { useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  regionCode: z.string(),
});

export type SetRegionInputs = z.infer<typeof schema>;

export const SetRegionPage = () => {
  const updateRegionForAccountContext = useUpdateRegionForAccountContext();
  const { regions } = useListAvailableRegionsForAccountContext();

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const { control, handleSubmit, setValue, watch } = useForm<SetRegionInputs>({
    resolver: zodResolver(schema),
    mode: 'onChange',
  });

  const selectedRegionCode = watch('regionCode');
  const label = regions?.find(r => r?.code === selectedRegionCode)?.name || 'Select Region';

  const regionDropdownRef = useRef<HTMLLabelElement>(null);

  const onSubmit: SubmitHandler<SetRegionInputs> = data => {
    updateRegionForAccountContext.mutate(data);
  };

  const close = () => {
    regionDropdownRef.current?.focus();
    regionDropdownRef.current?.blur();
  };

  const handleRegionDropdownItemClick = (regionCode: string) => {
    setValue('regionCode', regionCode);
    close();
  };

  return (
    <div className="h-100dvh w-100dvw overflow-auto bg-neutral-100 py-10 lg:py-20">
      <div className="m-auto max-w-98 flex flex-col px-4">
        <div className="self-center">
          <img className="h-auto w-50 sm:w-auto" src={brand} />
        </div>

        <p className="mt-14 color-neutral-800 typo-heading-4 lg:mt-28">Select region</p>

        <form className="contents" onSubmit={handleSubmit(onSubmit)}>
          <p className="mt-6 color-neutral-700 typo-text-s">
            Choose where your data will be stored. This will be applied to all accounts in this
            company and cannot be changed once selected.
          </p>
          <div className="mt-5">
            <Controller
              control={control}
              name="regionCode"
              render={() => (
                <Dropdown
                  ref={regionDropdownRef}
                  className="m-b-5 w-full"
                  label={label}
                  width="full"
                >
                  {regions?.map(regionData => (
                    <DropdownItem
                      key={regionData?.code}
                      onClick={() => handleRegionDropdownItemClick(regionData?.code ?? '')}
                    >
                      {regionData?.name}
                    </DropdownItem>
                  ))}
                </Dropdown>
              )}
            />
          </div>

          <div className="flex flex-col gap-2">
            <div className={cn('flex', 'flex-row', 'items-center', 'gap-2')}>
              <Checkbox
                checked={isCheckboxChecked}
                setToggleCheckbox={() => setIsCheckboxChecked(prev => !prev)}
              />
              <span className={cn('color-neutral-700', 'typo-text-s')}>
                I acknowledge that the region cannot be changed.
              </span>
            </div>
          </div>

          <Button
            className="mt-6 flex cursor-pointer items-center justify-center gap-1"
            disabled={!isCheckboxChecked || !selectedRegionCode}
            filled
            primary
            size="s"
            type="submit"
          >
            {updateRegionForAccountContext.isLoading && <Spinner className="animate-spin" />}
            Done
          </Button>
        </form>
      </div>
    </div>
  );
};
