import { Back } from '@/components/Back';
import { SideBar } from '@/components/SideBar';
import { SideTabPanel } from '@/components/SideTabPanel';
import { Table } from '@/components/Table';
import * as PATHS from '@/constants/paths';
import { OBJECT_ID } from '@/constants/policy';
import { queryClient } from '@/graphql/client';
import { PolicyObjectTypeInput, PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { useRolesQuery } from '@/hooks/useRolesQuery';
import { cn } from '@/utils/classname';
import { getCreateSubjectsPagesSidePanelLinks } from '@/utils/links';
import { parseAllRawValue } from '@/utils/misc';
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { useAccount } from '@/hooks/useAccount';
import { usePermissionPolicyMutation } from '@/hooks/usePermissionPolicyMutation';
import { useRolesMutation } from '@/hooks/useRolesMutation';
import { useGroupStore } from '@/stores/group';
import { clearRoleState, useRoleStore } from '@/stores/role';
import { Button, toast } from '@skand/ui';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RESOURCE_KEY } from '../../constants/permissions';
import { Resource, columns } from './createColumn';

import { READ_MORE_LINKS } from '@/constants/readMoreLinks';
import { ENTITLEMENT_NAME } from '@/hooks/useEntitlements';
import { filterHighestActionTypePerObjectType } from '@/utils/policy';
import { AccessGate, FeatureNotIncluded } from '../AccessGate';

interface CreateSubjectPermissionsPageProps {
  subjectType: PolicySubjectTypeInput;
}

export const CreateSubjectPermissionsPage = ({
  subjectType,
}: CreateSubjectPermissionsPageProps) => {
  const accountId = useAccount().account?.id;
  const history = useHistory();

  const { roleName, roleDescription, rolePermissionPolicies } = useRoleStore();
  const { groupPermissionPolicies } = useGroupStore();

  const resources = useMemo(() => {
    const map = new Map<string, Resource>();

    const selectedPolicies =
      subjectType === PolicySubjectTypeInput.Role
        ? rolePermissionPolicies
        : groupPermissionPolicies;

    const { sceneEntityIds, systemNodeIds } = selectedPolicies.reduce(
      (acc, currPolicy) => {
        if (currPolicy.objectId === OBJECT_ID.ALL) {
          return acc;
        }

        if (currPolicy.objectType === PolicyObjectTypeInput.SystemNode) {
          return { ...acc, systemNodeIds: [...acc.systemNodeIds, currPolicy.objectId as string] };
        }
        if (currPolicy.objectType === PolicyObjectTypeInput.SceneEntity) {
          return { ...acc, sceneEntityIds: [...acc.sceneEntityIds, currPolicy.objectId as string] };
        }

        return acc;
      },
      { sceneEntityIds: [], systemNodeIds: [] } as {
        sceneEntityIds: string[];
        systemNodeIds: string[];
      },
    );

    for (const policy of selectedPolicies) {
      const key = `${parseAllRawValue(policy.objectType as string)}-${policy.objectId}`;
      const resource = map.get(key);
      if (resource) {
        resource.policies.push(policy);
        resource.actionTypes.push(policy.actionType ?? '');
      } else {
        map.set(key, {
          key,
          subjectType,
          actionTypes: [policy.actionType ?? ''],
          objectId: policy.objectId ?? '',
          objectType: policy.objectType ?? '',
          policies: [policy],
          ...(policy.objectType === PolicyObjectTypeInput.SystemNode && { systemNodeIds }),
          ...(policy.objectType === PolicyObjectTypeInput.SceneEntity && { sceneEntityIds }),
        });
      }
    }

    const predefined_resources: Resource[] = [];
    if (!map.has(RESOURCE_KEY.ALL)) {
      predefined_resources.push({
        actionTypes: [],
        key: RESOURCE_KEY.ALL,
        subjectType,
        objectId: OBJECT_ID.ALL,
        objectType: PolicyObjectTypeInput.All,
        policies: [],
      });
    }

    if (!map.has(RESOURCE_KEY.PROJECT)) {
      predefined_resources.push({
        actionTypes: [],
        key: RESOURCE_KEY.PROJECT,
        subjectType,
        objectId: OBJECT_ID.ALL,
        objectType: PolicyObjectTypeInput.Project,
        policies: [],
      });
    }

    if (!map.has(RESOURCE_KEY.PROJECT_GROUP)) {
      predefined_resources.push({
        actionTypes: [],
        key: RESOURCE_KEY.PROJECT_GROUP,
        subjectType,
        objectId: OBJECT_ID.ALL,
        objectType: PolicyObjectTypeInput.ProjectGroup,
        policies: [],
      });
    }

    if (!map.has(RESOURCE_KEY.SYSTEM_NODE)) {
      predefined_resources.push({
        actionTypes: [],
        key: RESOURCE_KEY.SYSTEM_NODE,
        subjectType,
        objectId: OBJECT_ID.ALL,
        objectType: PolicyObjectTypeInput.SystemNode,
        policies: [],
      });
    }

    const resources = [...predefined_resources, ...map.values()]
      .sort((a, b) => a.key.localeCompare(b.key))
      .sort((a, b) => {
        const isWildcardA = a.key.includes('-*');
        const isWildcardB = b.key.includes('-*');

        if (isWildcardA && isWildcardB) {
          return a.key.localeCompare(b.key);
        }

        if (isWildcardA) {
          return -1;
        }

        if (isWildcardB) {
          return 1;
        }

        return a.key.localeCompare(b.key);
      });
    return resources;
  }, [rolePermissionPolicies, groupPermissionPolicies, subjectType]);

  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data: resources,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.key,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: { sorting },
  });

  const { upsertPermission } = usePermissionPolicyMutation();
  const { createRole } = useRolesMutation({
    onCreateRoleSuccess: async (roleId?: string) => {
      const upsertPermissionPolicyPayload = rolePermissionPolicies.map(policy => ({
        accountId,
        actionType: policy?.actionType,
        objectId: OBJECT_ID.ALL,
        objectType: parseAllRawValue(policy?.objectType as string),
        subjectId: roleId,
        subjectType: policy?.subjectType,
      }));

      const result = filterHighestActionTypePerObjectType(upsertPermissionPolicyPayload);

      // return;
      try {
        await upsertPermission.mutateAsync(result);
        queryClient.invalidateQueries(useRolesQuery.queryKey);

        toast({
          type: 'success',
          message: 'Role permission has been created successfully',
          lifespan: 10000,
        });

        history.push(PATHS.MANAGE_ROLES);
        clearRoleState();
      } catch (error) {
        toast({
          type: 'warn',
          message: 'Role permission creation failed!',
          lifespan: 10000,
        });
      }
    },
  });
  const handleCreateRole = useCallback(() => {
    createRole.mutate({
      name: roleName,
      description: roleDescription,
    });
  }, [createRole, roleDescription, roleName]);

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      <SideBar />
      <div className={cn('h-full', 'w-full', 'px-6', 'overflow-auto')}>
        <div
          className={cn(
            'b-b-1',
            'b-b-neutral-300',
            'b-b-solid',
            'flex',
            'items-center',
            'p-b-3',
            'p-t-30px',
          )}
        >
          <h1 className="ml-4 color-neutral-800 typo-heading-3">Permissions</h1>

          <div className="ml-auto flex gap-2">
            <>
              {subjectType === PolicySubjectTypeInput.User && (
                <Back to={PATHS.MANAGE_USERS}>Back to all users</Back>
              )}
              {subjectType === PolicySubjectTypeInput.Group && (
                <Back to={PATHS.MANAGE_GROUPS}>Back to all groups</Back>
              )}

              {subjectType === PolicySubjectTypeInput.Group && (
                <Button
                  className={cn('min-w-[134px]')}
                  filled
                  onClick={() => history.push(PATHS.MANAGE_GROUPS_CREATE_USERS)}
                  primary
                  size="s"
                >
                  Next
                </Button>
              )}
              {subjectType === PolicySubjectTypeInput.Role && (
                <Back to={PATHS.MANAGE_ROLES}>Back to all roles</Back>
              )}

              {subjectType === PolicySubjectTypeInput.Role && (
                <AccessGate
                  disabled={() => (
                    <FeatureNotIncluded
                      button={
                        <Button className="min-w-[134px] px-8" disabled filled primary size="s">
                          Create Role
                        </Button>
                      }
                      readMoreUrl={READ_MORE_LINKS.ROLE}
                    />
                  )}
                  enabled={() => (
                    <Button
                      className="min-w-[134px] px-8"
                      disabled={roleName === ''}
                      filled
                      onClick={handleCreateRole}
                      primary
                      size="s"
                    >
                      Create Role
                    </Button>
                  )}
                  entitlementCheck={{ featureName: ENTITLEMENT_NAME.ROLE }}
                  loading={() => (
                    <Button className="min-w-[134px] px-8" disabled filled primary size="s">
                      Create Role
                    </Button>
                  )}
                />
              )}
            </>
          </div>
        </div>

        <div className="ml-4 mt-3 h-[64px] flex items-start justify-between">
          <p className="color-neutral-800 typo-text-s">Add permissions</p>
        </div>

        <div className="ml-4 w-full flex">
          <Table table={table} tdProps={{ className: 'vertical-start' }} />

          <SideTabPanel
            links={getCreateSubjectsPagesSidePanelLinks({
              subjectType,
            })}
          />
        </div>
      </div>
    </div>
  );
};
