import { Back } from '@/components/Back';
import { DeleteButton } from '@/components/DeleteButton';
import { SideBar } from '@/components/SideBar';
import { SideTabPanel } from '@/components/SideTabPanel';
import { ROLE_DETAILS_PAGE_MODE } from '@/constants/role';
import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { useRolesMutation } from '@/hooks/useRolesMutation';
import { PATHS } from '@/index';
import { cn } from '@/utils/classname';
import { getCreateRolesPagesSidePanelLinks, getRolesPagesSidePanelLinks } from '@/utils/links';
import { Button, Input } from '@skand/ui';
import { FormEventHandler, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

export const Content = ({
  id,
  roleName,
  setRoleName,
  roleDescription,
  setRoleDescription,
  onSubmitEditRoleDetails,
  mode,
  isSystemDefault,
}: {
  id?: string;
  roleName: string;
  setRoleName: (value: string) => void;
  roleDescription: string;
  setRoleDescription: (value: string) => void;
  onSubmitEditRoleDetails: FormEventHandler<HTMLFormElement>;
  mode: keyof typeof ROLE_DETAILS_PAGE_MODE;
  isSystemDefault?: boolean;
}) => {
  const history = useHistory();
  const isEditMode = mode === ROLE_DETAILS_PAGE_MODE.EDIT;

  const { deleteRole } = useRolesMutation();

  const handleDelete = useCallback(() => {
    if (id) {
      deleteRole.mutate({ roleId: id });
      history.replace(PATHS.MANAGE_ROLES);
    }
  }, [deleteRole, history, id]);

  const sideTabPanelUrls =
    mode === 'CREATE'
      ? getCreateRolesPagesSidePanelLinks()
      : getRolesPagesSidePanelLinks(id as string);

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      <SideBar />
      <div className={cn('h-full', 'w-full', 'px-6', 'overflow-auto')}>
        <div
          className={cn(
            'b-b-1',
            'b-b-neutral-300',
            'b-b-solid',
            'flex',
            'items-center',
            'p-b-3',
            'p-t-30px',
            'justify-between',
          )}
        >
          <h1
            className="ml-4 max-w-800px truncate color-neutral-800 typo-heading-3"
            title={roleName ?? ''}
          >
            {isEditMode ? roleName : 'Create a new role'}
          </h1>
          <div className="ml-auto flex gap-2">
            {isEditMode ? (
              <Back to={PATHS.MANAGE_ROLES}>Back to all roles</Back>
            ) : (
              <>
                <Link to={PATHS.MANAGE_ROLES}>
                  <Button className="min-w-[134px] hover:cursor-pointer" size="s">
                    Cancel
                  </Button>
                </Link>

                <Button
                  className={cn('min-w-[134px]', { 'hover:cursor-pointer': !!roleName.trim() })}
                  disabled={!roleName.trim()}
                  filled
                  onClick={() => history.push(PATHS.MANAGE_ROLES_CREATE_PERMISSIONS)}
                  primary
                  size="s"
                >
                  Next
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="ml-4 mt-3 h-[64px] flex items-start justify-between">
          <p className="color-neutral-800 typo-text-s">
            {isEditMode
              ? isSystemDefault
                ? 'Permissions for this role cannot be edited because it is a system default. Create your own role to manage settings.'
                : 'Manage role details.'
              : 'Customize specific permissions to assign to users.'}
          </p>
        </div>
        <div className="flex">
          <div className="ml-4 flex flex-col gap-6">
            <form className="w-360px flex flex-col gap-6" onSubmit={onSubmitEditRoleDetails}>
              <p className="color-neutral-800 typo-heading-4">Enter role details</p>

              <div className="flex flex-col gap-3">
                <Input
                  disabled={isSystemDefault}
                  hint="Required"
                  label="Role name"
                  onChange={setRoleName}
                  tail={
                    isEditMode &&
                    isSystemDefault && (
                      <div
                        className="i-skand-help color-neutral-500"
                        data-tooltip-target="tooltip-default"
                        title="System default role names cannot be edited."
                      />
                    )
                  }
                  value={roleName}
                />
                <Input
                  disabled={isSystemDefault}
                  hint="Optional"
                  label="Description"
                  onChange={setRoleDescription}
                  tail={
                    isEditMode &&
                    isSystemDefault && (
                      <div
                        className="i-skand-help color-neutral-500"
                        data-tooltip-target="tooltip-default"
                        title="System default role description cannot be edited."
                      />
                    )
                  }
                  value={roleDescription}
                />
              </div>

              {isEditMode && (
                <Button
                  className={cn({ 'cursor-pointer': !isSystemDefault })}
                  disabled={isSystemDefault}
                  primary
                  size="s"
                  type="submit"
                >
                  Update
                </Button>
              )}
            </form>

            {isEditMode && (
              <div className="w-360px flex flex-col gap-6">
                <p className="color-neutral-800 typo-heading-4">Role controls</p>
                <DeleteButton
                  disabled={isSystemDefault}
                  onDelete={() => handleDelete()}
                  subjectType={PolicySubjectTypeInput.Role}
                />
              </div>
            )}
          </div>

          <SideTabPanel links={sideTabPanelUrls} />
        </div>
      </div>
    </div>
  );
};
