import * as PATHS from '@/constants/paths';
import { cn } from '@/utils/classname';
import { NavLink, NavLinkProps } from 'react-router-dom';

const Link = ({ children, ...props }: NavLinkProps) => {
  const className = (isActive: boolean) => {
    return cn(
      'px-4 py-3 block decoration-none',
      isActive
        ? 'bg-neutral-100 typo-text-s-em color-neutral-800 rounded-l-2'
        : 'typo-text-s color-neutral-500',
    );
  };

  return (
    <NavLink className={className} {...props}>
      {children}
    </NavLink>
  );
};

export const SideBar = () => {
  return (
    <div
      className={cn('w-230px', 'bg-neutral-200', 'flex-none', 'overflow-auto', 'pb-8', 'h-full')}
    >
      <h1
        className={cn(
          'b-b-1',
          'b-b-neutral-300',
          'b-b-solid',
          'color-neutral-800',
          'mx-6',
          'pb-14px',
          'pt-9',
          'typo-heading-4',
        )}
      >
        Manage
      </h1>

      <ul className={cn('m-t-4', 'p-l-2', 'list-none')}>
        <li>
          <Link to={PATHS.MANAGE_USERS}>Users</Link>
        </li>

        <li>
          <Link to={PATHS.MANAGE_GROUPS}>User Groups</Link>
        </li>

        <li>
          <Link to={PATHS.MANAGE_ROLES}>Roles</Link>
        </li>

        <li>
          <Link to={PATHS.MANAGE_TEMPLATES}>Account-Level Templates</Link>
        </li>
        <li>
          <Link to={PATHS.MANAGE_BRANDING}>Branding</Link>
        </li>
        <li>
          <Link to={PATHS.MANAGE_SUBSCRIPTION}>Subscription</Link>
        </li>
      </ul>
    </div>
  );
};
