import { AccessGate, FeatureNotIncluded } from '@/components/AccessGate';
import { MANAGE_TEMPLATES } from '@/constants/paths';
import { READ_MORE_LINKS } from '@/constants/readMoreLinks';
import { ANNOTATION_FIELD_TYPE, COLOR_FROM_FIELD_CUSTOM } from '@/constants/template';
import { queryClient } from '@/graphql/client';
import {
  CREATE_ACCOUNT_ANNOTATION_TEMPLATE,
  UPDATE_ANNOTATION_TEMPLATE,
} from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useAnnotationTemplates } from '@/hooks/useAnnotationTemplates';
import { ENTITLEMENT_NAME } from '@/hooks/useEntitlements';
import {
  changeTemplateColor,
  changeTemplateDesc,
  changeTemplateName,
  clearTemplate,
  useTemplate,
} from '@/stores/template';
import { cn } from '@/utils/classname';
import { Button, Input, Select } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useHistory } from 'react-router-dom';
import { Chip } from './Chip';
import { DropArea } from './DropArea';
import { FieldTable } from './FieldTable';

export interface TemplatePanelProps {
  mode: 'create' | 'view' | 'update';
}

export const TemplatePanel = ({ mode }: TemplatePanelProps) => {
  const template = useTemplate(state => state.template);
  const fields = useTemplate(state => state.fields);
  const history = useHistory();

  const createTemplate = useMutation({
    mutationFn: () =>
      request(CREATE_ACCOUNT_ANNOTATION_TEMPLATE, {
        request: {
          colorFromField: template?.colorFromField,
          name: template?.name,
          description: template?.description,
          fields: fields.map(field => ({ ...field, mode: undefined })),
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(useAnnotationTemplates.queryKey);
      clearTemplate();
      history.push(MANAGE_TEMPLATES);
    },
  });

  const updateTemplate = useMutation({
    mutationFn: () =>
      request(UPDATE_ANNOTATION_TEMPLATE, {
        request: {
          annotationTemplateId: template?.id as string,
          colorFromField: template?.colorFromField,
          name: template?.name,
          description: template?.description,
          // trim redundant fields
          fields: fields.map(field => ({ ...field, __typename: undefined, mode: undefined })),
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(useAnnotationTemplates.queryKey);
      clearTemplate();
      history.push(MANAGE_TEMPLATES);
    },
  });

  const handleCancel = useCallback(() => {
    clearTemplate();
    history.push(MANAGE_TEMPLATES);
  }, [history]);

  const handleSave = useCallback(() => {
    if (mode === 'create') createTemplate.mutate();
    else if (mode === 'update') updateTemplate.mutate();
  }, [createTemplate, mode, updateTemplate]);

  const buttonText = (
    <>
      {mode === 'create' && 'Create'}
      {mode === 'update' && 'Update'}
    </>
  );
  const disabledButton = (
    <Button className="w-136px" disabled filled primary size="s">
      {buttonText}
    </Button>
  );

  return (
    <div className={cn('bg-neutral-100 h-full flex-1 overflow-auto', 'px-4 pt-18px')}>
      <div className="mt-3 flex flex-row items-center justify-between b-b-1 b-b-neutral-300 b-b-solid pb-3">
        <h1 className="ml-4 color-neutral-800 typo-heading-3">
          {mode === 'create' && 'Create a new template'}
          {mode === 'update' && 'Edit template'}
          {mode === 'view' && template?.name}
        </h1>

        <div className="flex gap-3">
          <Button className="w-136px" onClick={handleCancel} size="s">
            {mode === 'view' ? 'Close' : 'Cancel'}
          </Button>

          {mode !== 'view' && (
            <AccessGate
              disabled={() => (
                <FeatureNotIncluded
                  button={disabledButton}
                  readMoreUrl={READ_MORE_LINKS.TEMPLATE}
                />
              )}
              enabled={() => (
                <Button
                  className="w-136px"
                  disabled={!template?.name}
                  filled
                  onClick={handleSave}
                  primary
                  size="s"
                >
                  {buttonText}
                </Button>
              )}
              entitlementCheck={{ featureName: ENTITLEMENT_NAME.ANNOTATION_TEMPLATE }}
              loading={() => disabledButton}
            />
          )}
        </div>
      </div>

      <div className="ml-4">
        <p className="mt-3 color-neutral-800 typo-text-s">
          {mode === 'view'
            ? 'This template is a system default and cannot be edited. Create a new template to customise.'
            : 'Add fields and customise values.'}
        </p>
        {mode !== 'view' && (
          <>
            <p className="mt-6 color-neutral-800 typo-text-m">Template details</p>
            <div className="mt-3 flex gap-3">
              <div className="flex-1">
                <Input
                  hint={template?.name ? undefined : 'Required'}
                  label="Name"
                  onChange={changeTemplateName}
                  value={template?.name ?? ''}
                />
              </div>
              <div className="flex-1">
                <Input
                  label="Description"
                  onChange={changeTemplateDesc}
                  value={template?.description ?? ''}
                />
              </div>
            </div>
            <p className="mt-3 color-neutral-800 typo-text-m">Colour source</p>
            <Select
              className="mt-3 w-50%"
              onChange={changeTemplateColor}
              options={[
                { key: COLOR_FROM_FIELD_CUSTOM, name: 'Custom (choose from explore)' },
                ...(fields
                  ?.filter(field => field?.type === ANNOTATION_FIELD_TYPE.SELECT)
                  .map(field => ({ key: field?.name ?? '', name: field?.name ?? '' })) ?? []),
              ]}
              placeholder="Colour source"
              value={template?.colorFromField ?? COLOR_FROM_FIELD_CUSTOM}
            />
            <p className="mt-6 color-neutral-800 typo-text-m">Add field type</p>
            <div className="mt-3 flex gap-3">
              <DndProvider backend={HTML5Backend}>
                <Chip type="FILE" />
                <Chip type="DATE" />
                <Chip type="SELECT" />
                <Chip type="URL" />
                <Chip type="TEXT" />
                <Chip type="IMAGE" />
              </DndProvider>
            </div>
          </>
        )}
      </div>

      <FieldTable />

      {mode !== 'view' && (
        <DndProvider backend={HTML5Backend}>
          <DropArea />
        </DndProvider>
      )}
    </div>
  );
};
