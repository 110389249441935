import { gql } from './codegen';

export const ACCOUNT_BY_CONTEXT = gql(`
  query AccountByContext {
    accountByContext {
      id
      name
    }
  }
`);

export const LIST_ANNOTATION_TEMPLATES_BY_ACCOUNT_CONTEXT = gql(`
  query ListAnnotationTemplatesByAccountContext {
    listAnnotationTemplatesByAccountContext {
      id
      accountId
      projectId
      level
      colorFromField
      name
      description
      fields {
        ... on AnnotationTemplateTextField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateFileField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateUrlField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateDateField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
        ... on AnnotationTemplateSelectField {
          id
          name
          order
          type
          isDeleted
          isVisible
          options {
            color
            id
            value
          }
        }
        ... on AnnotationTemplateImageField {
          id
          name
          order
          type
          isDeleted
          isVisible
        }
      }
      createdBy
      createdByUserId
      createdAt
      updatedByUserId
      updatedAt
    }
  }
`);

export const LIST_ANNOTATION_TEMPLATES_BY_PROJECT_ID = gql(`
  query ListAnnotationTemplatesByProjectId($projectId: ID!) {
    listAnnotationTemplatesByProjectId(projectId: $projectId) {
      id
      accountId
      projectId
      level
      colorFromField
      name
      description
      fields {
        ... on AnnotationTemplateTextField {
            name
            order
            type
            isDeleted
            isVisible
        }
        ... on AnnotationTemplateFileField {
            name
            order
            type
            isDeleted
            isVisible
        }
        ... on AnnotationTemplateUrlField {
            name
            order
            type
            isDeleted
            isVisible
        }
        ... on AnnotationTemplateDateField {
            name
            order
            type
            isDeleted
            isVisible
        }
        ... on AnnotationTemplateSelectField {
            name
            order
            type
            isDeleted
            isVisible
            options {
              color
              value
            }
        }
        ... on AnnotationTemplateImageField {
          name
          order
          type
          isDeleted
          isVisible
        }
      }
      createdBy
      createdByUserId
      createdAt
      updatedByUserId
      updatedAt
    }
  }
`);

export const READ_USER_BY_CONTEXT = gql(`
  query ReadUserByContext {
    readUserByContext {
      id
      accountId
      name {
        first
        last
      }
      email
      isAdmin
      isActive
    }
  }
`);

export const USERS_BY_ACCOUNT_ID = gql(`
  query UsersByAccountId {
    usersByAccountId {
      displayName
      email
      firstName
      id
      lastName
      roleIds
      roles {
        id
        name
      }
    }
  }
`);

export const LIST_USERS_BY_ACCOUNT_ID = gql(`
  query ListUsersByAccountId {
    listUsersByAccountId {
      id
      accountId
      name {
        first
        last
      }
      email
      status
      isActive
      isAdmin
      invitationToken
      invitationCreatedAt
      invitationAcceptedAt
      createdAt
      updatedAt
      lastLoginAt
    }
  }
`);

export const READ_USER_BY_ID = gql(`
  query ReadUserById($userId: String!) {
    readUserById(userId: $userId) {
      id
      accountId
      name {
        first
        last
      }
      email
      status
      isActive
      isAdmin
      invitationToken
      invitationCreatedAt
      invitationAcceptedAt
      createdAt
      updatedAt
    }
  }
`);

export const LIST_USER_GROUPS_BY_ACCOUNT_CONTEXT = gql(`
  query ListUserGroupsByAccountContext {
    listUserGroupsByAccountContext {
      createdAt
      description
      id
      name
      updatedAt
      permissionPolicies {
        accountId
        actionType
        objectId
        objectType
        subjectId
        subjectType
      }
      userGroupToRolePolicies {
        accountId
        sourceId
        sourceType
        targetId
        targetType
      }
      userToUserGroupPolicies {
        accountId
        sourceId
        sourceType
        targetId
        targetType
      }
    }
  }
`);

export const USER_BY_USER_ID = gql(`
  query UserByUserId($userId: String) {
    user(userId: $userId) {
      accountId
      activeStatus
      discardedAt
      displayName
      email
      firstName
      id
      isAdmin
      lastLoginAt
      lastName
      lastSignInAt
      measurementSystem
      roleIds
      roles {
        id
        name
      }
      signInCount
      slackUsername
    }
  }
`);

export const LIST_ROLES_BY_ACCOUNT_CONTEXT = gql(`
  query ListRolesByAccountContext {
    listRolesByAccountContext {
      accountId
      createdAt
      createdBy
      id
      name
      description
      permissionPolicies {
        accountId
        actionType
        objectId
        objectType
        subjectId
        subjectType
      }
      updatedAt
    }
  }
`);

export const READ_ROLE_BY_ID = gql(`
  query ReadRoleById($roleId: ID!) {
    readRoleById(roleId: $roleId) {
      accountId
      createdAt
      createdBy
      id
      name
      description
      permissionPolicies {
        accountId
        actionType
        objectId
        objectType
        subjectId
        subjectType
      }
      updatedAt
      userGroupToRolePolicies {
        accountId
        sourceId
        sourceType
        targetId
        targetType
        createdAt
        updatedAt
      }
      userToRolePolicies {
        accountId
        sourceId
        sourceType
        targetId
        targetType
        createdAt
        updatedAt
      }
    }
  }
`);

export const READ_USER_GROUP_BY_ID = gql(`
  query ReadUserGroupById($userGroupId: ID!) {
    readUserGroupById(userGroupId: $userGroupId) {
      createdAt
      description
      id
      name
      updatedAt
      permissionPolicies {
        accountId
        actionType
        objectId
        objectType
        subjectId
        subjectType
      }
      userGroupToRolePolicies {
        accountId
        sourceId
        sourceType
        targetId
        targetType
      }
      userToUserGroupPolicies {
        accountId
        sourceId
        sourceType
        targetId
        targetType
      }
    }
  }
`);

export const LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT = gql(`
  query ListPermissionPoliciesWithAccountContext(
    $actionType: PolicyActionTypeInput,
    $objectId: String,
    $objectType: PolicyObjectTypeInput,
    $subjectId: String,
    $subjectType: PolicySubjectTypeInput,
  ) {
    listPermissionPoliciesWithAccountContext(
      actionType: $actionType,
      objectId: $objectId,
      objectType: $objectType, 
      subjectId: $subjectId,
      subjectType: $subjectType,
    ) {
      actionType
      objectId
      objectType
      subjectId
      subjectType
    }
  }
`);

export const LIST_GROUPING_POLICIES_WITH_ACCOUNT_CONTEXT = gql(`
  query listGroupingPoliciesWithAccountContext(
    $sourceId: String,
    $sourceType: PolicySubjectTypeInput,
    $targetId: String,
    $targetType: PolicySubjectTypeInput,
  ) {
    listGroupingPoliciesWithAccountContext(
      sourceId: $sourceId,
      sourceType: $sourceType,
      targetId: $targetId,
      targetType: $targetType
    ) {
      sourceId
      sourceType
      targetId
      targetType
    }
  }
`);

export const READ_PROJECT_BY_ID = gql(`
  query readProjectById($projectId: ID!) {
    readProjectById(projectId: $projectId) {
      name
    }
  }
`);

export const LIST_PROJECTS_BY_ACCOUNT_CONTEXT = gql(`
  query ListProjectsByAccountContext {
    listProjectsByAccountContext {
      accountId
      address
      createdAt
      geoid
      id
      name
      projectGroupId
    }
  }
`);

export const LIST_PROJECT_GROUPS_BY_ACCOUNT_CONTEXT = gql(`
  query ListProjectGroupsByAccountContext {
    listProjectGroupsByAccountContext {
      accountId
      createdAt
      description
      id
      name
      updatedAt
    }
  }
`);

export const LIST_SYSTEM_NODES_BY_PARENT_NODE_ID = gql(`
  query ListSystemNodesByParentNodeId($pageSize: Int!, $pageIndex: Int!, $parentNodeId: ID, $searchTerm: String, $nodeKinds: [SystemNodeKindInput]) {
    listSystemNodesByParentNodeId(pageSize: $pageSize, pageIndex: $pageIndex, parentNodeId: $parentNodeId, searchTerm: $searchTerm, nodeKinds: $nodeKinds){
      totalNumberOfPages
      totalNumberOfFiles
      data {
        ... on FileNode {
          id
          kind
          parentNodeId
          name
          createdAt
          createdUser {
            id
            displayName
            firstName
            lastName
          }
          lastDownloadedAt
          lastDownloadedUser {
            id
            displayName
            firstName
            lastName
          }
          fileId
        }
        ... on FolderNode {
          id
          kind
          parentNodeId
          name
          createdAt
          createdUser {
            id
            displayName
            firstName
            lastName
          }
          lastDownloadedAt
          lastDownloadedUser {
            id
            displayName
            firstName
            lastName
          }
        }
        ... on LinkNode {
          id
          kind
          parentNodeId
          name
          createdAt
          createdUser {
            id
            displayName
            firstName
            lastName
          }
          lastDownloadedAt
          lastDownloadedUser {
            id
            displayName
            firstName
            lastName
          }
          sourceNodeId
        }
      }
    }
  }
`);

export const FIND_SYSTEM_NODES_BY_IDS = gql(`
  query FindSystemNodesByIds($systemNodeIds: [ID]!) {
    findSystemNodesByIds(systemNodeIds: $systemNodeIds) {
      ... on FileNode {
        id
        kind
        name
      }
      ... on FolderNode {
        id
        kind
        name
      }
      ... on LinkNode {
        id
        kind
        name
      }
    }
  }
`);

export const PARENT_NODES_CHAIN_BY_PARENT_NODE_ID = gql(`
  query ParentNodesChainByParentNodeId($parentNodeId: ID) {
    parentNodesChainByParentNodeId(parentNodeId: $parentNodeId) {
      id
      kind
      accountId
      parentNodeId
      name
      createdAt
    }
  }
`);

export const LIST_LAYERS_BY_PROJECT_ID = gql(`
  query ListLayerByProjectId($projectId: ID!) {
    listLayersByProjectId(projectId: $projectId) {
      id
      name
      captureDate
      worldPosition
      worldRotation
      mainSceneEntityId
      supportingSceneEntityIds
      status
      formatType
      defaultMaxHeight
      defaultMinHeight
    }
  }
`);

export const LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES = gql(`
  query ListSceneEntitiesByProjectIdAndRendererTypes($projectId: ID!, $rendererTypes: [String]!) {
    listSceneEntitiesByProjectIdAndRendererTypes(projectId: $projectId, rendererTypes: $rendererTypes) {
      id
      name
      position {
        x
        y
        z
      }
      rotation {
        x
        y
        z
        w
      }
      rendererType
      parentSceneEntityId
      renderObject {
        ... on KmlRenderObject {
          __typename
          id
          file {
            ... on AssetFile {
              id
              signedGetObjectUrl
            }
          }
        }
        ... on IfcRenderObject {
          __typename
          id
          epsgCode
          file {
            ... on AssetFile {
              id
              signedGetObjectUrl
              storage {
                size
              }
            }
          }
        }
        ... on DxfRenderObject {
          __typename
          id
          epsgCode
          file {
            ... on AssetFile {
              id
              signedGetObjectUrl
            }
          }
        }
        ... on Tileset3dRenderObject {
          __typename
          id
          kind
          file {
            ... on Tileset3dFile {
              id
              httpUrl
            }
          }
          process {
            ... on Tileset3dProcess {
              lastStepIsDone
            }
          }
        }
        ... on Ortho2dRenderObject {
          __typename
          id
          kind
          process {
            ... on Ortho2dProcess {
              steps {
                stepName
                comment
              }
            }
          }
          file {
            ... on Ortho2dFile {
              id
              httpUrl
            }
          }
        }
        ... on ObjRenderObject {
          __typename
          id
          epsgCode
          kind
          process {
            ... on ObjProcess {
              steps {
                stepName
                comment
              }
            }
          }
          file {
            ... on ObjFile {
              id
              httpUrl
            }
          }
        }
        ... on CesiumIonRenderObject {
          __typename
          id
          kind
          cesiumIonId
          process {
            ... on CesiumIonProcess {
              steps {
                stepName
                comment
              }
            }
          }
        }
        ... on PanoramicRenderObject {
          __typename
          id
          panoramicImages {
            id
            name
            relativePosition
            relativeRotation

            fileId
            file {
              ... on ImageFile {
                fileName
                signedGetObjectUrl
                thumbnailUrl
              }
            }
          }
        }
        ... on ImageProjectionRenderObject {
          __typename
          id
          kind
          cameraModels {
            imageSize
            principalPoint
            id
            distortion {
              k1
              k2
              k3
              p1
              p2
            }
            focalLength
            projectionString
            sensorSize
            skew
          }
          images {
            id
            cameraModelId
            cameraRelativePosition
            cameraRelativeRotation
            projectedRelativePosition
            projectedRelativeRotation

            extrinsicPosition
            extrinsicRotation
            extrinsicScale

            fileId
            file {
              ... on ImageFile {
                fileName
              }
            }
          }
        }
        ... on AnnotationRenderObject {
          __typename
          id
          createdAt
        }
      }

      relativePosition
      relativeRotation
    }
  }
`);

export const FIND_SCENE_ENTITIES_BY_IDS = gql(`
  query FindSceneEntitiesByIds($sceneEntityIds: [ID]!) {
    findSceneEntitiesByIds(sceneEntityIds: $sceneEntityIds) {
      id
      projectId
      name
      renderObject {
        ... on ImageProjectionRenderObject {
          id
          kind
          name
        }
        ... on KmlRenderObject {
          id
          kind
          name
        }
        ... on ObjRenderObject {
          id
          kind
          name
        }
        ... on PanoramicRenderObject {
          id
          kind
          name
        }
        ... on Tileset3dRenderObject {
          id
          kind
          name
        }
        ... on CesiumIonRenderObject {
          id
          kind
          name
        }
        ... on Ortho2dRenderObject {
          id
          kind
          name
        }
        ... on IfcRenderObject {
          id
          kind
          name
        }
        ... on DxfRenderObject {
          id
          kind
          name
        }
        ... on AnnotationRenderObject {
          id
          kind
          name
        }
        ... on PanoramicVideoRenderObject {
          id
          kind
          name
        }
      }
    }
  }
`);

export const LIST_ANNOTATIONS_BY_PROJECT_ID = gql(`
  query ListAnnotationsByProjectId($projectId: ID!) {
    listAnnotationsByProjectId(projectId: $projectId) {
      color
      groupId
      id
      name
      templateId
      annotationId
      annotation2d {
        imageFileId
        points {
          x
          y
        }
        shapeType
      }
      annotation3d {
        positions {
          x
          y
          z
        }
        rotations {
          w
          x
          y
          z
        }
        shapeType
      }

      fields {
        ... on AnnotationDateField {
          __typename
          end
          name
          start
          type
        }
        ... on AnnotationFileField {
          __typename
          files {
            fileId
            name
          }
          name
          type
        }
        ... on AnnotationSelectField {
          __typename
          name
          type
          value
        }
        ... on AnnotationTextField {
          __typename
          name
          text
          type
        }
        ... on AnnotationUrlField {
          __typename
          name
          type
          urls {
            url
            name
          }
        }
      }
    }
  }
`);

export const FILE_IMAGE_ORIGINAL_URL_BY_ID = gql(`
  query FileImageOriginalById($fileId: ID!) {
    file(fileId: $fileId) {
      ... on ImageFile {
        __typename
        id
        originalUrl
      }
    }
  }
`);

export const ACCOUNT_V2_BY_CONTEXT = gql(`
  query AccountV2ByContext {
    readAccountByContext {
      id
      usesApplicationCredentials
      branding {
        color
        isActive
        hasSkandWatermark
        reportBannerFileId
        webappBannerFileId
        webappLogoFileId

        reportBannerFile {
          __typename
          ... on ImageFile { originalUrl }
        }

        webappBannerFile {
          __typename
          ... on ImageFile { originalUrl }
        }

        webappLogoFile {
          __typename
          ... on ImageFile { originalUrl }
        }
      }
    }
  }
`);

export const GET_REGION_BY_ACCOUNT_CONTEXT = gql(`
  query GetRegionByAccountContext {
    getRegionByAccountContext {
      code
      name
    }
  }
  `);

export const LIST_AVAILABLE_REGIONS_FOR_ACCOUNT_CONTEXT = gql(`
  query ListAvailableRegionsForAccountContext {
    listAvailableRegionsForAccountContext {
      code
      name
    }
  }
  `);

export const DELETE_ANNOTATION_TEMPLATE = gql(`
  mutation DeleteAnnotationTemplate($annotationTemplateId: ID!) {
    deleteAnnotationTemplate(annotationTemplateId: $annotationTemplateId)
  }
`);

export const LIST_FEATURE_ENTITLEMENTS_BY_ACCOUNT_CONTEXT = gql(`
  query ListFeatureEntitlementsByAccountContext {
    listFeatureEntitlementsByAccountContext {
      enabled
      infinity
      name
      total
      type
      used
    }
  }
`);

export const CHECK_SUPER_ADMIN = gql(`
  query CheckSuperAdmin {
    checkSuperAdmin
  }
`);
