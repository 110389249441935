import {
  PermissionPolicy,
  PolicyActionTypeInput,
  PolicyObjectTypeInput,
  PolicySubjectTypeInput,
} from '@/graphql/codegen/graphql';
import { parseAllRawValue } from '@/utils/misc';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ACTION_TYPE_MAP,
  ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP,
  OBJECT_TYPE_MAP,
  OBJECT_TYPE_TO_ACTION_TYPES_MAP,
} from '../../constants/permissions';
import { NameCell } from './NameCell';
import { PermissionCell } from './PermissionCell';
import { AdminAllPermissionCell } from './AdminAllPermissionCell';
import { usePermissionStore } from '@/stores/permission';

export interface Resource {
  key: string; // objectType-objectId
  objectId: string;
  policies: PermissionPolicy[];
  actionTypes: string[];
  objectType: string;
  systemNodeIds?: string[];
  sceneEntityIds?: string[];
  subjectType: PolicySubjectTypeInput;
}

const columnHelper = createColumnHelper<Resource>();

export const columns = [
  columnHelper.accessor('objectType', {
    header: 'resource type',
    cell: ({ getValue }) => (
      <span className="color-neutral-800 typo-text-s">
        {OBJECT_TYPE_MAP[parseAllRawValue(getValue()) as PolicyObjectTypeInput]}
      </span>
    ),
  }),

  columnHelper.accessor('key', {
    header: 'resource',
    cell: ({ row }) => <NameCell row={row} />,
  }),

  columnHelper.display({
    id: 'actionTypes',
    header: () => (
      <div className="flex items-center">
        <div className="w-5 flex justify-center mr-2"></div>
        <span>permission</span>
      </div>
    ),
    cell: ({ row }) => {
      return row.id == 'ALL-*' ? (
        <AdminAllPermissionCell row={row} />
      ) : (
        <PermissionCell row={row} />
      );
    },
  }),

  columnHelper.display({
    id: 'description',
    header: 'description',
    cell: ({ row }) => {
      const expandedPermissions = usePermissionStore(state => state.expandedPermissions);

      const getExpandKey = (actionType: string) => {
        return `${row.id}-${actionType}`;
      };

      return (
        <div>
          {OBJECT_TYPE_TO_ACTION_TYPES_MAP[
            parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput
          ].map(actionType => {
            const descriptions = [
              <p className="mt-3 color-neutral-500 typo-text-s first:mt-0" key={actionType}>
                {
                  ACTION_TYPE_MAP[actionType as PolicyActionTypeInput].descriptions[
                    parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput
                  ]
                }
              </p>,
            ];

            const hasSubActions = actionType in ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP;
            if (hasSubActions && expandedPermissions.has(getExpandKey(actionType))) {
              const objectType = parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput;
              if (objectType === PolicyObjectTypeInput.Project) {
                const subActionTypes =
                  ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP[
                    actionType as keyof typeof ACTION_TYPE_TO_SUB_ACTION_TYPES_MAP
                  ][objectType];
                if (subActionTypes) {
                  subActionTypes.forEach((subActionType: PolicyActionTypeInput) => {
                    descriptions.push(
                      <p className="mt-3 color-neutral-500 typo-text-s" key={subActionType}>
                        {ACTION_TYPE_MAP[subActionType].descriptions[objectType]}
                      </p>,
                    );
                  });
                }
              }
            }

            return descriptions;
          })}
        </div>
      );
    },
  }),
];
